<template>
  <transition name="fade">
    <section class="absolute bottom-0 right-auto sm:right-0 sm:bottom-auto sm:-top-5 p-3 sm:shadow-2xl bg-accent-lighter sm:bg-white">
      <a
        v-for="item in socials"
        :key="item.name"
        :href="item.url"
        :aria-label="`Deel dit artikel via ${item.name}`"
        target="_blank"
        rel="nofollow noopener"
        class="mx-2 hover:text-secondary"
      >
        <font-awesome-icon :icon="item.icon" size="2x"/>
      </a>
    </section>
  </transition>
</template>

<script>
export default {
  data: () => ({
    socials: [],
  }),
  mounted() {
    const currentUrl = window.location.origin + this.$route.path;

    this.socials = [
      {
        name: 'Facebook',
        icon: ['fab', 'facebook'],
        url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
      },
      {
        name: 'LinkedIn',
        icon: ['fab', 'linkedin'],
        url: `https://www.linkedin.com/shareArticle?url=${currentUrl}`,
      },
      {
        name: 'Twitter',
        icon: ['fab', 'twitter-square'],
        url: `https://twitter.com/intent/tweet?text=${currentUrl}`,
      },
      {
        name: 'Whatsapp',
        icon: ['fab', 'whatsapp-square'],
        url: `https://api.whatsapp.com/send?text=${currentUrl}`,
      },
      {
        name: 'Mail',
        icon: ['fas', 'envelope-square'],
        url: `mailto:?subject=Golfgeschiedenis&body=${currentUrl}`,
      },
    ];
  },
};
</script>

<style  scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
