<template>
  <section class="bg-accent-lighter">
    <div class="bg-white h-70v sm:h-66v md:h-81v text-primary sm:mx-10 md:mx-40 xl:mx-64 mt-24 px-8 pt-8 sm:px-16 sm:pt-16">
      <template v-if="error.statusCode === 410 || error.statusCode === 404">
        <h1 class="text-5xl sm:text-5xl font-semibold leading-none">{{ page.title }}</h1>
        <!--eslint-disable-next-line-->
        <div class="pt-6 pb-12 content" v-html="page.tekst"/>
        <nuxt-link aria-label="Keer terug naar de homepage" class="px-8 py-4 bg-secondary text-white uppercase" to="/">
          Terug naar de homepage
        </nuxt-link>
      </template>
      <h1 v-else>
        Er is een fout opgetreden
        <nuxt-link to="/" aria-label="Keer terug naar de homepage">
          Terug naar de Homepage
        </nuxt-link>
      </h1>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>

<style  scoped>
.content {
  ::v-deep a {
    font-weight: 700;
  }
}
</style>
