<template>
  <div
    class="flex flex-col items-center"
    :class="[
      {'pb-0': hasFilters},
      inHeader ? 'px-2 border-2 border-black rounded': 'p-2 bg-secondary',
    ]"
  >
    <div
      class="w-full bg-white flex items-center"
      :class="[
        {'h-16': !inHeader},
      ]"
    >
      <input
        v-model="q"
        class="flex-grow text-black"
        :class="[
          inHeader ? 'p-1': 'p-3',
        ]"
        :placeholder="$route.path !== '/en' ? 'Op zoek naar specifieke golfgeschiedenis?' : 'Looking for specific golf history?'"
        :aria-label="$route.path !== '/en' ? 'Zoek hier naar een specifieke golfgeschiedenis' : 'Search for specific golf history'"
        type="text"
        @keyup.enter="performSearch"
      >
      <button
        class="flex-shrink pr-4 font-semibold text-secondary focus:text-primary"
        @click="performSearch"
      >
        {{ $route.path !== '/en' ? 'Vind!' : 'Find!' }}
      </button>
    </div>
    <!--  FILTER  -->
    <div v-if="hasFilters" class="flex flex-wrap flex-row flex-grow space-x-4 items-center w-full px-3">
      <div v-for="(filter, index) in filters" :key="filter.title" class="relative inline-block text-left">
        <button
          type="button"
          class="inline-flex items-center text-sm xxl:text-base w-full py-2 bg-secondary text-white leading-5 underline font-semibold focus:text-primary"
          :aria-label="filter.title"
          @click="toggle(index)"
        >
          {{ activeOptions[index] ? activeOptions[index] : `Kies een ${filter.title}` }}
          <font-awesome-icon :icon="['fas', 'caret-down']" class="ml-2 h-4 w-4" aria-hidden="true"/>
        </button>
        <transition name="fade">
          <div
            v-if="openIndex === index"
            :class="index % 2 === 0 ? 'origin-top-left -left-5' : 'origin-top-right -right-5 text-right md:origin-top-left md:-left-5 md:text-left'"
            class="absolute w-56 z-50"
          >
            <div class="bg-secondary">
              <div v-for="option in filter.filters" :key="option" class="py-1" @click="selectOption(option, index)">
                <span :aria-label="option" class="block px-5 py-2 text-sm leading-5 text-white">
                  {{ option }}
                </span>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    withFilters: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      default: '',
    },
    prefillFilters: {
      type: Object,
      default: () => ({}),
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    q: '',
    openIndex: null,
    filters: null,
    activeOptions: {},
  }),
  computed: {
    hasFilters() {
      return this.withFilters && (this.filters !== null && Object.keys(this.filters).length > 0);
    },
  },
  created() {
    if (this.category) {
      this.getFilterOptions();
    }
  },
  methods: {
    performSearch() {
      if (this.q !== '') {
        const params = Object.entries(this.activeOptions).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
        this.$router.push(`/zoekresultaten?${params}&q=${this.q}&cat=${this.category}`);
      }
    },
    toggle(index) {
      if (this.openIndex === index) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
    selectOption(option, key) {
      this.activeOptions[key] = option;
      this.openIndex = null;

      this.$emit('event:filters', this.activeOptions);
    },
    async getFilterOptions() {
      const {data} = await this.$axios.get('categories/filters', {
        params: {
          category: this.category,
        },
      });
      if (typeof data === 'object') {
        this.filters = data;

        if (Object.keys(this.prefillFilters).length) {
          this.activeOptions = this.prefillFilters;
        }
      }
    },
  },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
