import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61acb862 = () => interopDefault(import('../pages/colf.vue' /* webpackChunkName: "pages/colf" */))
const _02595872 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _dc08ef5c = () => interopDefault(import('../pages/en.vue' /* webpackChunkName: "pages/en" */))
const _6eae574b = () => interopDefault(import('../pages/golf.vue' /* webpackChunkName: "pages/golf" */))
const _0e330ac7 = () => interopDefault(import('../pages/kolf.vue' /* webpackChunkName: "pages/kolf" */))
const _14ba8abd = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _5af2deb6 = () => interopDefault(import('../pages/publicaties/index.vue' /* webpackChunkName: "pages/publicaties/index" */))
const _c3663986 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _5e602146 = () => interopDefault(import('../pages/publicaties/_slug.vue' /* webpackChunkName: "pages/publicaties/_slug" */))
const _fa60b18e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3f5c5bde = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/colf",
    component: _61acb862,
    name: "colf"
  }, {
    path: "/contact",
    component: _02595872,
    name: "contact"
  }, {
    path: "/en",
    component: _dc08ef5c,
    name: "en"
  }, {
    path: "/golf",
    component: _6eae574b,
    name: "golf"
  }, {
    path: "/kolf",
    component: _0e330ac7,
    name: "kolf"
  }, {
    path: "/over",
    component: _14ba8abd,
    name: "over"
  }, {
    path: "/publicaties",
    component: _5af2deb6,
    name: "publicaties"
  }, {
    path: "/zoekresultaten",
    component: _c3663986,
    name: "zoekresultaten"
  }, {
    path: "/publicaties/:slug",
    component: _5e602146,
    name: "publicaties-slug"
  }, {
    path: "/",
    component: _fa60b18e,
    name: "index"
  }, {
    path: "/*",
    component: _3f5c5bde,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
